<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" stripe pagination max-items="10" search :data="clientsData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 mr-5 md:mb-0" @click="$router.push('/apps/ordonnance/ordonnance-add')">Nouveau ordonnance</vs-button>
                <template v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">
                  <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="CurrentOfficineSelectClient()">
                    <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                  </vs-select>
                </template>
              </template>
              <template slot="thead">
                <vs-th sort-key="numero">
                  N°
                </vs-th>

                <vs-th sort-key="Nom">
                  Nom client
                </vs-th>
                <vs-th sort-key="Prenoms">
                  Prénoms client
                </vs-th>
                <vs-th sort-key="adresse">
                  Adresse client
                </vs-th>
                <vs-th sort-key="SimpleFoyer">
                  Email
                </vs-th>
                <vs-th sort-key="DoubleFoyer">
                  Contact
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <template v-if="tr.ordonnances.length > 0">
                    <vs-td :data="data[indextr]">
                      {{indextr + 1}}
                    </vs-td>
                    <vs-td :data="data[indextr].Nom">
                      {{data[indextr].Nom}}
                    </vs-td>
                    <vs-td :data="data[indextr].Prenoms">
                      {{data[indextr].Prenoms}}
                    </vs-td>
                    <vs-td :data="data[indextr].Adresse.adresse">
                      {{data[indextr].Adresse.adresse}}
                    </vs-td>
                    <vs-td :data="data[indextr].Adresse.email">
                      {{data[indextr].Adresse.email}}
                    </vs-td>
                    <vs-td :data="data[indextr].Adresse.mobile">
                      {{data[indextr].Adresse.mobile}}
                    </vs-td>

                    <template class="expand-user" slot="expand">
                      <div class="con-expand-users w-full">
                          <vs-th sort-key="numero">
                            N°
                          </vs-th>
                          <vs-th sort-key="numero">
                            Code Ord
                          </vs-th>

                          <vs-th sort-key="DateOrd">
                            Date Prescription
                          </vs-th>
                          <vs-th sort-key="Prescripteur">
                            Prescripteur
                          </vs-th>
                          <vs-th sort-key="typeVerre">
                            Type de verre
                          </vs-th>
                          <vs-th sort-key="PhotoChrom">
                            Photochromique
                          </vs-th>
                          <vs-th sort-key="AntiReflet">
                            Anti-Reflet
                          </vs-th>
                          <vs-th sort-key="active">
                            Teinte
                          </vs-th>
                          <vs-th sort-key="active">
                            Valide
                          </vs-th>
                          <vs-th>
                            Actions
                          </vs-th>
                        <vs-tr :data="tr2" :key="indextr2" v-for="(tr2, indextr2) in data[indextr].ordonnances">
                          <vs-td>
                            {{indextr2+1}}
                          </vs-td>
                          <vs-td>
                            {{tr2.RefOrd}}
                          </vs-td>
                          <vs-td :data="tr2.DateOrd">
                            {{tr2.DateOrd | moment("calendar", "July 10 2011")}}
                          </vs-td>
                          <vs-td>
                            {{ getPrescripteurName(tr2.Prescripteur) }}
                          </vs-td>
                          <vs-td>
                            {{tr2.typeVerre}}
                          </vs-td>
                          <vs-td>
                            <template v-if="tr2.PhotoChrom == true">
                              <vs-chip transparent color="success">
                                Oui
                              </vs-chip>

                            </template>
                            <template v-else>
                              <vs-chip transparent color="danger">
                                Non
                              </vs-chip>
                            </template>
                          </vs-td>
                          <vs-td>
                            <template v-if="tr2.AntiReflet == true">
                              <vs-chip transparent color="success">
                                Oui
                              </vs-chip>

                            </template>
                            <template v-else>
                              <vs-chip transparent color="danger">
                                Non
                              </vs-chip>
                            </template>
                          </vs-td>
                          <vs-td>
                              {{tr2.Teinte}}
                          </vs-td>
                          <vs-td>
                            <template v-if="tr2.active == true">
                              <vs-chip transparent color="success">
                                Oui
                              </vs-chip>

                            </template>
                            <template v-else>
                              <vs-chip transparent color="danger">
                                Non
                              </vs-chip>
                            </template>
                          </vs-td>
                          <vs-td :data="data[indextr]._id">
                            <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                              <feather-icon title="Voir" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showOrdonnance(tr._id, tr2.RefOrd)" />
                              <feather-icon title="Modifier" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editData(tr._id, tr2.RefOrd)" />
                              <feather-icon title="Supprimer" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr._id, tr2.RefOrd)" />
                            </div>
                          </vs-td>
                        </vs-tr>
                      </div>
                    </template>
                  </template>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selected:[],
      ordonnances:[],
      ordonnanceId: '',
      idOfficine: null,
      activeUserInfos: null,
      clientId: null,
      currentRefOrd: null
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''
    },
    clientsData () {
      const ordonnences = []
      const clients = this.$store.state.client.clients

      clients.forEach(client => {
        if (client.ordonnances.length > 0) {
          ordonnences.push(client)
        }
      })
      return ordonnences
    },
    officines () {
      return this.$store.state.officine.officines
    },
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    }
  },

  methods: {
    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    showOrdonnance (clientId, RefOrd) {
      this.$router.push(`/apps/ordonnance/ordonnance-view/${clientId}/${RefOrd}`).catch((err) => { console.log(err) })
    },
    editData (clientId, RefOrd) {
      this.$router.push(`/apps/ordonnance/ordonnance-edit/${clientId}/${RefOrd}`).catch((err) => { console.log(err) })
    },
    confirmDeleteRecord (clientId, RefOrd) {
      this.clientId = clientId
      this.currentRefOrd = RefOrd

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Ordonnance suppression',
        text: `Vous allez supprimer l'ordonnace "${RefOrd}"`,
        acceptText: 'Supprimer',
        accept: this.deleteData
      })
    },
    deleteData () {
      const payload = {
        clientId: this.clientId,
        refOrd: this.currentRefOrd
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/removeOrdonnance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary',
            time: 5000
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getPrescripteurName (id) {
      const prescripteur = this.prescripteurs.find((p) => p._id === id) ? this.prescripteurs.find((p) => p._id === id) : ''
      return `${prescripteur.NomPresc} ${prescripteur.PrenomPresc}`
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => { console.log(err) })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    CurrentOfficineSelectClient () {
      console.log(this.idOfficine)
      this.getClientsOfficine(this.idOfficine)
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine

      this.getClientsOfficine(this.idOfficine)
    },
    getClientsOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    }
  },
  created () {
    this.activeUserInfo()
    this.getOfficines()
    this.getPrescripteurs()
  }
}
</script>

